.heading {
    font-size: 18px !important;
    line-height: 1.625 !important;
    opacity: 1;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: bold !important;
    color: #47536e !important;
    padding-bottom: 12px;
}

.description {
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(123, 128, 154);
    font-weight: 400;
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title .heading {
    color: #c5974e !important;
    font-size: 26px !important;
}