.testimonial .testimonial-container {
    text-align: center;
}

@media (min-width: 769px) {
    .testimonial .testimonial-container {
        width: 70%;
        margin: auto;
    }

    .testimonial-card {
        width: 70%;
        margin: auto;
        position: relative;
        background: #fff;
    }

}

.testimonial-card {
    width: 80%;
    margin: auto;
    position: relative;
    background: #fff;
}

.testimonial-card .heading {
    font-size: 18px;
    font-weight: bold;
    padding: 0px !important;
    margin: 10px 0 5px 0 !important;
    color: #111;
}

.testimonial-user-loaction {
    font-size: 14px !important;
    color: #999;
    margin: 0;
}

.testimonial-card svg:first-child {
    transform: rotate(180deg);
}