.footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer .footer-links ul svg {
    padding-right: 2px;
    color: #9fb2d8;
    font-size: 18px;
    line-height: 1;
}

.footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    cursor: pointer;
    align-items: center;
    transition: all ease-in-out .4s;
}

.footer .footer-links ul li:hover {
    margin-left: 15px;
    font-weight: 500;
}

.footer .social-links {
    display: flex;
    align-items: center;
    margin: 1rem 0px;
}

.footer .social-links .social-media-item {
    font-size: 18px;
    background: #c5974e;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0px 5px;
}

.footer .social-links .social-media-item:hover {
    background: rgb(2, 0, 36) !important;
    background: linear-gradient(90deg, rgb(244 190 104) 0%, rgb(254 156 0) 100%) !important;
    color: #fff;
    text-decoration: none;
}

.footer .footer-bottom .description {
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 768px) {
    .footer {
        float: none;
        text-align: left;
    }

    .footer .footer-bottom .description {
        text-align: center;
        margin: .5rem 0;
    }
}

@media (min-width: 769px) {

    .footer .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footer .footer-top {
        max-width: 1140px;
        margin: auto;
    }

    .footer {
        background: #fff;
        text-align: left;
        font-size: 14px;
    }

    .footer .footer-links .description,
    .footer .footer-contact .description {
        font-size: 14px !important;
    }

}