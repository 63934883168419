.features .features-content {
    display: flex;
    align-items: stretch;
}

.features .features-image {
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.features .features-list {
    padding: 30px 0;
}

.features .features-item {
    display: flex;
}

.features .features-item svg {
    font-size: 40px;
    float: left;
    color: #c5974e;
}

.features .features-item .icon-box .heading {
    font-size: 18px !important;
    margin: 0px 10px;
}
.features .features-item .icon-box .description{
    margin: 0px 10px;
}