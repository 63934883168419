.counting {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .counting {
        display: block;
        margin: 20px 0;
    }
}