body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
div,
i,
a,
button,
input,
textarea,
label,
li {
  font-family: -apple-system, BlinkMacSystemFont,  'Poppins', sans-serif !important;
}