.hero {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 30px 0;
    align-items: center;
}

.hero .hero-content {
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.hero h1 {
    margin: 0 0 10px 0;
    font-size: 48px !important;
    font-weight: 500 !important;
    line-height: 56px !important;
    color: #c5974e;
    text-transform: capitalize;
}

.hero h2 {
    color: #515f7d;
    margin-bottom: 50px;
    font-size: 20px;
}

.hero .download-btn {
    font-weight: 500;
    font-size: 15px;
    padding: 8px 24px 10px 24px;
    border-radius: 3px;
    transition: 0.5s;
    color: #fff;
    background: #47536e;
    position: relative;
    cursor: pointer;
}

.hero .download-btn:hover {
    background: rgb(2, 0, 36) !important;
    background: linear-gradient(90deg, rgb(244 190 104) 0%, rgb(254 156 0) 100%) !important;
}


@media (max-width: 768px) {
    .hero .hero-content {
        text-align: center;
    }

    .hero h1 {
        font-size: 28px !important;
        line-height: 36px !important;
    }

    .hero h2 {
        font-size: 16px !important;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .hero .download-btn {
        font-size: 14px !important;
        padding: 10px;
        border-radius: 3px;
        transition: 0.5s;
        color: #fff;
        background: #47536e;
        position: relative;
        cursor: pointer;
    }

    .hero .btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 769px) {
    .hero .hero-content {
        display: flex;
    }

    .hero .btn-group {
        display: flex;
        align-items: center;
    }
}