.faq .faq-title .heading {
    padding: 0px;
    font-size: 16px !important;
}

.faq .faq-icon {
    font-size: 1.5rem;
    color: #c5974e;
    display: flex;
    align-items: center;
    justify-self: center;
}

.faq .faq-container {
    width: 70%;
    margin: 0px auto 20px auto;
    border: none;
    padding: 5px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media (max-width: 768px) {
    .faq .faq-container {
        width: 100%;
        padding: 0px;
    }

    .faq .faq-title .heading {
        font-size: 14px !important;
    }

    .faq .faq-icon {
    }
}