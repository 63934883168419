.contact .contant-info {
    padding: 30px;
    background: #fff;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact .info-icon {
    font-size: 20px;
    float: left;
    width: 44px;
    height: 44px;
    background-color: #fffaf2;
    color: #c5974e;
    border-radius: 50px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact .info-icon:hover {
    background-color: #c5974e;
    color: #fffaf2;
}

.send-msg-btn {
    background: #c5974e !important;
}

.send-msg-btn:hover {
    background: rgb(2, 0, 36) !important;
    background: linear-gradient(90deg, rgb(244 190 104) 0%, rgb(254 156 0) 100%) !important;
}